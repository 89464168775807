/*
 * Cookie 2.2.1 https://cdnjs.cloudflare.com/ajax/libs/js-cookie/2.2.1/js.cookie.min.js
 * For the accessibility
 * Check: https://cdnjs.com/libraries/js-cookie/2.2.1
 */
!function (e) {
    var n;
    if ("function" == typeof define && define.amd && (define(e), n = !0), "object" == typeof exports && (module.exports = e(), n = !0), !n) {
        var t = window.Cookies, o = window.Cookies = e();
        o.noConflict = function () {
            return window.Cookies = t, o
        }
    }
}(function () {
    function f() {
        for (var e = 0, n = {}; e < arguments.length; e++) {
            var t = arguments[e];
            for (var o in t) n[o] = t[o]
        }
        return n
    }

    function a(e) {
        return e.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent)
    }

    return function e(u) {
        function c() {
        }

        function t(e, n, t) {
            if ("undefined" != typeof document) {
                "number" == typeof (t = f({path: "/"}, c.defaults, t)).expires && (t.expires = new Date(1 * new Date + 864e5 * t.expires)), t.expires = t.expires ? t.expires.toUTCString() : "";
                try {
                    var o = JSON.stringify(n);
                    /^[\{\[]/.test(o) && (n = o)
                } catch (e) {
                }
                n = u.write ? u.write(n, e) : encodeURIComponent(String(n)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent), e = encodeURIComponent(String(e)).replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent).replace(/[\(\)]/g, escape);
                var r = "";
                for (var i in t) t[i] && (r += "; " + i, !0 !== t[i] && (r += "=" + t[i].split(";")[0]));
                return document.cookie = e + "=" + n + r
            }
        }

        function n(e, n) {
            if ("undefined" != typeof document) {
                for (var t = {}, o = document.cookie ? document.cookie.split("; ") : [], r = 0; r < o.length; r++) {
                    var i = o[r].split("="), c = i.slice(1).join("=");
                    n || '"' !== c.charAt(0) || (c = c.slice(1, -1));
                    try {
                        var f = a(i[0]);
                        if (c = (u.read || u)(c, f) || a(c), n) try {
                            c = JSON.parse(c)
                        } catch (e) {
                        }
                        if (t[f] = c, e === f) break
                    } catch (e) {
                    }
                }
                return e ? t[e] : t
            }
        }

        return c.set = t, c.get = function (e) {
            return n(e, !1)
        }, c.getJSON = function (e) {
            return n(e, !0)
        }, c.remove = function (e, n) {
            t(e, "", f(n, {expires: -1}))
        }, c.defaults = {}, c.withConverter = e, c
    }(function () {
    })
});

/*
 * loadJS https://github.com/muicss/loadjs 4.2.0
 * Check: https://cdnjs.com/libraries/loadjs
 */
loadjs = function () {
    var h = function () {
    }, c = {}, u = {}, f = {};

    function o(e, n) {
        if (e) {
            var r = f[e];
            if (u[e] = n, r) for (; r.length;) r[0](e, n), r.splice(0, 1)
        }
    }

    function l(e, n) {
        e.call && (e = {success: e}), n.length ? (e.error || h)(n) : (e.success || h)(e)
    }

    function d(r, t, s, i) {
        var c, o, e = document, n = s.async, u = (s.numRetries || 0) + 1, f = s.before || h,
            l = r.replace(/[\?|#].*$/, ""), a = r.replace(/^(css|img)!/, "");
        i = i || 0, /(^css!|\.css$)/.test(l) ? ((o = e.createElement("link")).rel = "stylesheet", o.href = a, (c = "hideFocus" in o) && o.relList && (c = 0, o.rel = "preload", o.as = "style")) : /(^img!|\.(png|gif|jpg|svg|webp)$)/.test(l) ? (o = e.createElement("img")).src = a : ((o = e.createElement("script")).src = r, o.async = void 0 === n || n), !(o.onload = o.onerror = o.onbeforeload = function (e) {
            var n = e.type[0];
            if (c) try {
                o.sheet.cssText.length || (n = "e")
            } catch (e) {
                18 != e.code && (n = "e")
            }
            if ("e" == n) {
                if ((i += 1) < u) return d(r, t, s, i)
            } else if ("preload" == o.rel && "style" == o.as) return o.rel = "stylesheet";
            t(r, n, e.defaultPrevented)
        }) !== f(r, o) && e.head.appendChild(o)
    }

    function r(e, n, r) {
        var t, s;
        if (n && n.trim && (t = n), s = (t ? r : n) || {}, t) {
            if (t in c) throw"LoadJS";
            c[t] = !0
        }

        function i(n, r) {
            !function (e, t, n) {
                var r, s, i = (e = e.push ? e : [e]).length, c = i, o = [];
                for (r = function (e, n, r) {
                    if ("e" == n && o.push(e), "b" == n) {
                        if (!r) return;
                        o.push(e)
                    }
                    --i || t(o)
                }, s = 0; s < c; s++) d(e[s], r, n)
            }(e, function (e) {
                l(s, e), n && l({success: n, error: r}, e), o(t, e)
            }, s)
        }

        if (s.returnPromise) return new Promise(i);
        i()
    }

    return r.ready = function (e, n) {
        return function (e, r) {
            e = e.push ? e : [e];
            var n, t, s, i = [], c = e.length, o = c;
            for (n = function (e, n) {
                n.length && i.push(e), --o || r(i)
            }; c--;) t = e[c], (s = u[t]) ? n(t, s) : (f[t] = f[t] || []).push(n)
        }(e, function (e) {
            l(n, e)
        }), r
    }, r.done = function (e) {
        o(e, [])
    }, r.reset = function () {
        c = {}, u = {}, f = {}
    }, r.isDefined = function (e) {
        return e in c
    }, r
}();

/*
 * Original file: /npm/intersection-observer@0.12.0/intersection-observer.js
 * Check: https://www.jsdelivr.com/package/npm/intersection-observer
 */
!function () {
    "use strict";
    if ("object" == typeof window) if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) "isIntersecting" in window.IntersectionObserverEntry.prototype || Object.defineProperty(window.IntersectionObserverEntry.prototype, "isIntersecting", {
        get: function () {
            return this.intersectionRatio > 0
        }
    }); else {
        var t = function (t) {
            for (var e = window.document, o = i(e); o;) o = i(e = o.ownerDocument);
            return e
        }(), e = [], o = null, n = null;
        s.prototype.THROTTLE_TIMEOUT = 100, s.prototype.POLL_INTERVAL = null, s.prototype.USE_MUTATION_OBSERVER = !0, s._setupCrossOriginUpdater = function () {
            return o || (o = function (t, o) {
                n = t && o ? l(t, o) : {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: 0,
                    height: 0
                }, e.forEach((function (t) {
                    t._checkForIntersections()
                }))
            }), o
        }, s._resetCrossOriginUpdater = function () {
            o = null, n = null
        }, s.prototype.observe = function (t) {
            if (!this._observationTargets.some((function (e) {
                return e.element == t
            }))) {
                if (!t || 1 != t.nodeType) throw new Error("target must be an Element");
                this._registerInstance(), this._observationTargets.push({
                    element: t,
                    entry: null
                }), this._monitorIntersections(t.ownerDocument), this._checkForIntersections()
            }
        }, s.prototype.unobserve = function (t) {
            this._observationTargets = this._observationTargets.filter((function (e) {
                return e.element != t
            })), this._unmonitorIntersections(t.ownerDocument), 0 == this._observationTargets.length && this._unregisterInstance()
        }, s.prototype.disconnect = function () {
            this._observationTargets = [], this._unmonitorAllIntersections(), this._unregisterInstance()
        }, s.prototype.takeRecords = function () {
            var t = this._queuedEntries.slice();
            return this._queuedEntries = [], t
        }, s.prototype._initThresholds = function (t) {
            var e = t || [0];
            return Array.isArray(e) || (e = [e]), e.sort().filter((function (t, e, o) {
                if ("number" != typeof t || isNaN(t) || t < 0 || t > 1) throw new Error("threshold must be a number between 0 and 1 inclusively");
                return t !== o[e - 1]
            }))
        }, s.prototype._parseRootMargin = function (t) {
            var e = (t || "0px").split(/\s+/).map((function (t) {
                var e = /^(-?\d*\.?\d+)(px|%)$/.exec(t);
                if (!e) throw new Error("rootMargin must be specified in pixels or percent");
                return {value: parseFloat(e[1]), unit: e[2]}
            }));
            return e[1] = e[1] || e[0], e[2] = e[2] || e[0], e[3] = e[3] || e[1], e
        }, s.prototype._monitorIntersections = function (e) {
            var o = e.defaultView;
            if (o && -1 == this._monitoringDocuments.indexOf(e)) {
                var n = this._checkForIntersections, r = null, s = null;
                this.POLL_INTERVAL ? r = o.setInterval(n, this.POLL_INTERVAL) : (h(o, "resize", n, !0), h(e, "scroll", n, !0), this.USE_MUTATION_OBSERVER && "MutationObserver" in o && (s = new o.MutationObserver(n)).observe(e, {
                    attributes: !0,
                    childList: !0,
                    characterData: !0,
                    subtree: !0
                })), this._monitoringDocuments.push(e), this._monitoringUnsubscribes.push((function () {
                    var t = e.defaultView;
                    t && (r && t.clearInterval(r), c(t, "resize", n, !0)), c(e, "scroll", n, !0), s && s.disconnect()
                }));
                var u = this.root && (this.root.ownerDocument || this.root) || t;
                if (e != u) {
                    var a = i(e);
                    a && this._monitorIntersections(a.ownerDocument)
                }
            }
        }, s.prototype._unmonitorIntersections = function (e) {
            var o = this._monitoringDocuments.indexOf(e);
            if (-1 != o) {
                var n = this.root && (this.root.ownerDocument || this.root) || t;
                if (!this._observationTargets.some((function (t) {
                    var o = t.element.ownerDocument;
                    if (o == e) return !0;
                    for (; o && o != n;) {
                        var r = i(o);
                        if ((o = r && r.ownerDocument) == e) return !0
                    }
                    return !1
                }))) {
                    var r = this._monitoringUnsubscribes[o];
                    if (this._monitoringDocuments.splice(o, 1), this._monitoringUnsubscribes.splice(o, 1), r(), e != n) {
                        var s = i(e);
                        s && this._unmonitorIntersections(s.ownerDocument)
                    }
                }
            }
        }, s.prototype._unmonitorAllIntersections = function () {
            var t = this._monitoringUnsubscribes.slice(0);
            this._monitoringDocuments.length = 0, this._monitoringUnsubscribes.length = 0;
            for (var e = 0; e < t.length; e++) t[e]()
        }, s.prototype._checkForIntersections = function () {
            if (this.root || !o || n) {
                var t = this._rootIsInDom(),
                    e = t ? this._getRootRect() : {top: 0, bottom: 0, left: 0, right: 0, width: 0, height: 0};
                this._observationTargets.forEach((function (n) {
                    var i = n.element, s = u(i), h = this._rootContainsTarget(i), c = n.entry,
                        a = t && h && this._computeTargetAndRootIntersection(i, s, e), l = null;
                    this._rootContainsTarget(i) ? o && !this.root || (l = e) : l = {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: 0,
                        height: 0
                    };
                    var f = n.entry = new r({
                        time: window.performance && performance.now && performance.now(),
                        target: i,
                        boundingClientRect: s,
                        rootBounds: l,
                        intersectionRect: a
                    });
                    c ? t && h ? this._hasCrossedThreshold(c, f) && this._queuedEntries.push(f) : c && c.isIntersecting && this._queuedEntries.push(f) : this._queuedEntries.push(f)
                }), this), this._queuedEntries.length && this._callback(this.takeRecords(), this)
            }
        }, s.prototype._computeTargetAndRootIntersection = function (e, i, r) {
            if ("none" != window.getComputedStyle(e).display) {
                for (var s, h, c, a, f, d, g, m, v = i, _ = p(e), b = !1; !b && _;) {
                    var w = null, y = 1 == _.nodeType ? window.getComputedStyle(_) : {};
                    if ("none" == y.display) return null;
                    if (_ == this.root || 9 == _.nodeType) if (b = !0, _ == this.root || _ == t) o && !this.root ? !n || 0 == n.width && 0 == n.height ? (_ = null, w = null, v = null) : w = n : w = r; else {
                        var I = p(_), E = I && u(I), T = I && this._computeTargetAndRootIntersection(I, E, r);
                        E && T ? (_ = I, w = l(E, T)) : (_ = null, v = null)
                    } else {
                        var R = _.ownerDocument;
                        _ != R.body && _ != R.documentElement && "visible" != y.overflow && (w = u(_))
                    }
                    if (w && (s = w, h = v, c = void 0, a = void 0, f = void 0, d = void 0, g = void 0, m = void 0, c = Math.max(s.top, h.top), a = Math.min(s.bottom, h.bottom), f = Math.max(s.left, h.left), d = Math.min(s.right, h.right), m = a - c, v = (g = d - f) >= 0 && m >= 0 && {
                        top: c,
                        bottom: a,
                        left: f,
                        right: d,
                        width: g,
                        height: m
                    } || null), !v) break;
                    _ = _ && p(_)
                }
                return v
            }
        }, s.prototype._getRootRect = function () {
            var e;
            if (this.root && !d(this.root)) e = u(this.root); else {
                var o = d(this.root) ? this.root : t, n = o.documentElement, i = o.body;
                e = {
                    top: 0,
                    left: 0,
                    right: n.clientWidth || i.clientWidth,
                    width: n.clientWidth || i.clientWidth,
                    bottom: n.clientHeight || i.clientHeight,
                    height: n.clientHeight || i.clientHeight
                }
            }
            return this._expandRectByRootMargin(e)
        }, s.prototype._expandRectByRootMargin = function (t) {
            var e = this._rootMarginValues.map((function (e, o) {
                return "px" == e.unit ? e.value : e.value * (o % 2 ? t.width : t.height) / 100
            })), o = {top: t.top - e[0], right: t.right + e[1], bottom: t.bottom + e[2], left: t.left - e[3]};
            return o.width = o.right - o.left, o.height = o.bottom - o.top, o
        }, s.prototype._hasCrossedThreshold = function (t, e) {
            var o = t && t.isIntersecting ? t.intersectionRatio || 0 : -1,
                n = e.isIntersecting ? e.intersectionRatio || 0 : -1;
            if (o !== n) for (var i = 0; i < this.thresholds.length; i++) {
                var r = this.thresholds[i];
                if (r == o || r == n || r < o != r < n) return !0
            }
        }, s.prototype._rootIsInDom = function () {
            return !this.root || f(t, this.root)
        }, s.prototype._rootContainsTarget = function (e) {
            var o = this.root && (this.root.ownerDocument || this.root) || t;
            return f(o, e) && (!this.root || o == e.ownerDocument)
        }, s.prototype._registerInstance = function () {
            e.indexOf(this) < 0 && e.push(this)
        }, s.prototype._unregisterInstance = function () {
            var t = e.indexOf(this);
            -1 != t && e.splice(t, 1)
        }, window.IntersectionObserver = s, window.IntersectionObserverEntry = r
    }

    function i(t) {
        try {
            return t.defaultView && t.defaultView.frameElement || null
        } catch (t) {
            return null
        }
    }

    function r(t) {
        this.time = t.time, this.target = t.target, this.rootBounds = a(t.rootBounds), this.boundingClientRect = a(t.boundingClientRect), this.intersectionRect = a(t.intersectionRect || {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: 0,
            height: 0
        }), this.isIntersecting = !!t.intersectionRect;
        var e = this.boundingClientRect, o = e.width * e.height, n = this.intersectionRect, i = n.width * n.height;
        this.intersectionRatio = o ? Number((i / o).toFixed(4)) : this.isIntersecting ? 1 : 0
    }

    function s(t, e) {
        var o, n, i, r = e || {};
        if ("function" != typeof t) throw new Error("callback must be a function");
        if (r.root && 1 != r.root.nodeType && 9 != r.root.nodeType) throw new Error("root must be a Document or Element");
        this._checkForIntersections = (o = this._checkForIntersections.bind(this), n = this.THROTTLE_TIMEOUT, i = null, function () {
            i || (i = setTimeout((function () {
                o(), i = null
            }), n))
        }), this._callback = t, this._observationTargets = [], this._queuedEntries = [], this._rootMarginValues = this._parseRootMargin(r.rootMargin), this.thresholds = this._initThresholds(r.threshold), this.root = r.root || null, this.rootMargin = this._rootMarginValues.map((function (t) {
            return t.value + t.unit
        })).join(" "), this._monitoringDocuments = [], this._monitoringUnsubscribes = []
    }

    function h(t, e, o, n) {
        "function" == typeof t.addEventListener ? t.addEventListener(e, o, n || !1) : "function" == typeof t.attachEvent && t.attachEvent("on" + e, o)
    }

    function c(t, e, o, n) {
        "function" == typeof t.removeEventListener ? t.removeEventListener(e, o, n || !1) : "function" == typeof t.detatchEvent && t.detatchEvent("on" + e, o)
    }

    function u(t) {
        var e;
        try {
            e = t.getBoundingClientRect()
        } catch (t) {
        }
        return e ? (e.width && e.height || (e = {
            top: e.top,
            right: e.right,
            bottom: e.bottom,
            left: e.left,
            width: e.right - e.left,
            height: e.bottom - e.top
        }), e) : {top: 0, bottom: 0, left: 0, right: 0, width: 0, height: 0}
    }

    function a(t) {
        return !t || "x" in t ? t : {
            top: t.top,
            y: t.top,
            bottom: t.bottom,
            left: t.left,
            x: t.left,
            right: t.right,
            width: t.width,
            height: t.height
        }
    }

    function l(t, e) {
        var o = e.top - t.top, n = e.left - t.left;
        return {top: o, left: n, height: e.height, width: e.width, bottom: o + e.height, right: n + e.width}
    }

    function f(t, e) {
        for (var o = e; o;) {
            if (o == t) return !0;
            o = p(o)
        }
        return !1
    }

    function p(e) {
        var o = e.parentNode;
        return 9 == e.nodeType && e != t ? i(e) : (o && o.assignedSlot && (o = o.assignedSlot.parentNode), o && 11 == o.nodeType && o.host ? o.host : o)
    }

    function d(t) {
        return t && 9 === t.nodeType
    }
}();

/*
 * Font Face Observer v2.1.0 - © Bram Stein. License: BSD-3-Clause
 * Check: https://github.com/bramstein/fontfaceobserver
 */
(function () {
    function l(a, b) {
        document.addEventListener ? a.addEventListener("scroll", b, !1) : a.attachEvent("scroll", b)
    }

    function m(a) {
        document.body ? a() : document.addEventListener ? document.addEventListener("DOMContentLoaded", function c() {
            document.removeEventListener("DOMContentLoaded", c);
            a()
        }) : document.attachEvent("onreadystatechange", function k() {
            if ("interactive" == document.readyState || "complete" == document.readyState) document.detachEvent("onreadystatechange", k), a()
        })
    };

    function t(a) {
        this.a = document.createElement("div");
        this.a.setAttribute("aria-hidden", "true");
        this.a.appendChild(document.createTextNode(a));
        this.b = document.createElement("span");
        this.c = document.createElement("span");
        this.h = document.createElement("span");
        this.f = document.createElement("span");
        this.g = -1;
        this.b.style.cssText = "max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;";
        this.c.style.cssText = "max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;";
        this.f.style.cssText = "max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;";
        this.h.style.cssText = "display:inline-block;width:200%;height:200%;font-size:16px;max-width:none;";
        this.b.appendChild(this.h);
        this.c.appendChild(this.f);
        this.a.appendChild(this.b);
        this.a.appendChild(this.c)
    }

    function u(a, b) {
        a.a.style.cssText = "max-width:none;min-width:20px;min-height:20px;display:inline-block;overflow:hidden;position:absolute;width:auto;margin:0;padding:0;top:-999px;white-space:nowrap;font-synthesis:none;font:" + b + ";"
    }

    function z(a) {
        var b = a.a.offsetWidth, c = b + 100;
        a.f.style.width = c + "px";
        a.c.scrollLeft = c;
        a.b.scrollLeft = a.b.scrollWidth + 100;
        return a.g !== b ? (a.g = b, !0) : !1
    }

    function A(a, b) {
        function c() {
            var a = k;
            z(a) && a.a.parentNode && b(a.g)
        }

        var k = a;
        l(a.b, c);
        l(a.c, c);
        z(a)
    };

    function B(a, b) {
        var c = b || {};
        this.family = a;
        this.style = c.style || "normal";
        this.weight = c.weight || "normal";
        this.stretch = c.stretch || "normal"
    }

    var C = null, D = null, E = null, F = null;

    function G() {
        if (null === D) if (J() && /Apple/.test(window.navigator.vendor)) {
            var a = /AppleWebKit\/([0-9]+)(?:\.([0-9]+))(?:\.([0-9]+))/.exec(window.navigator.userAgent);
            D = !!a && 603 > parseInt(a[1], 10)
        } else D = !1;
        return D
    }

    function J() {
        null === F && (F = !!document.fonts);
        return F
    }

    function K() {
        if (null === E) {
            var a = document.createElement("div");
            try {
                a.style.font = "condensed 100px sans-serif"
            } catch (b) {
            }
            E = "" !== a.style.font
        }
        return E
    }

    function L(a, b) {
        return [a.style, a.weight, K() ? a.stretch : "", "100px", b].join(" ")
    }

    B.prototype.load = function (a, b) {
        var c = this, k = a || "BESbswy", r = 0, n = b || 3E3, H = (new Date).getTime();
        return new Promise(function (a, b) {
            if (J() && !G()) {
                var M = new Promise(function (a, b) {
                    function e() {
                        (new Date).getTime() - H >= n ? b(Error("" + n + "ms timeout exceeded")) : document.fonts.load(L(c, '"' + c.family + '"'), k).then(function (c) {
                            1 <= c.length ? a() : setTimeout(e, 25)
                        }, b)
                    }

                    e()
                }), N = new Promise(function (a, c) {
                    r = setTimeout(function () {
                        c(Error("" + n + "ms timeout exceeded"))
                    }, n)
                });
                Promise.race([N, M]).then(function () {
                        clearTimeout(r);
                        a(c)
                    },
                    b)
            } else m(function () {
                function v() {
                    var b;
                    if (b = -1 != f && -1 != g || -1 != f && -1 != h || -1 != g && -1 != h) (b = f != g && f != h && g != h) || (null === C && (b = /AppleWebKit\/([0-9]+)(?:\.([0-9]+))/.exec(window.navigator.userAgent), C = !!b && (536 > parseInt(b[1], 10) || 536 === parseInt(b[1], 10) && 11 >= parseInt(b[2], 10))), b = C && (f == w && g == w && h == w || f == x && g == x && h == x || f == y && g == y && h == y)), b = !b;
                    b && (d.parentNode && d.parentNode.removeChild(d), clearTimeout(r), a(c))
                }

                function I() {
                    if ((new Date).getTime() - H >= n) d.parentNode && d.parentNode.removeChild(d), b(Error("" +
                        n + "ms timeout exceeded")); else {
                        var a = document.hidden;
                        if (!0 === a || void 0 === a) f = e.a.offsetWidth, g = p.a.offsetWidth, h = q.a.offsetWidth, v();
                        r = setTimeout(I, 50)
                    }
                }

                var e = new t(k), p = new t(k), q = new t(k), f = -1, g = -1, h = -1, w = -1, x = -1, y = -1,
                    d = document.createElement("div");
                d.dir = "ltr";
                u(e, L(c, "sans-serif"));
                u(p, L(c, "serif"));
                u(q, L(c, "monospace"));
                d.appendChild(e.a);
                d.appendChild(p.a);
                d.appendChild(q.a);
                document.body.appendChild(d);
                w = e.a.offsetWidth;
                x = p.a.offsetWidth;
                y = q.a.offsetWidth;
                I();
                A(e, function (a) {
                    f = a;
                    v()
                });
                u(e,
                    L(c, '"' + c.family + '",sans-serif'));
                A(p, function (a) {
                    g = a;
                    v()
                });
                u(p, L(c, '"' + c.family + '",serif'));
                A(q, function (a) {
                    h = a;
                    v()
                });
                u(q, L(c, '"' + c.family + '",monospace'))
            })
        })
    };
    "object" === typeof module ? module.exports = B : (window.FontFaceObserver = B, window.FontFaceObserver.prototype.load = B.prototype.load);
}());

/*
 * Default Prevents Passive Events https://www.jsdelivr.com/package/npm/default-passive-events
 * 2.0
 */
var e,
    t = ["scroll", "wheel", "touchstart", "touchmove", "touchenter", "touchend", "touchleave", "mouseout", "mouseleave", "mouseup", "mousedown", "mousemove", "mouseenter", "mousewheel", "mouseover"];
if (function () {
    var e = !1;
    try {
        var t = Object.defineProperty({}, "passive", {
            get: function () {
                e = !0
            }
        });
        window.addEventListener("test", null, t), window.removeEventListener("test", null, t)
    } catch (e) {
    }
    return e
}()) {
    var o = EventTarget.prototype.addEventListener;
    e = o, EventTarget.prototype.addEventListener = function (o, r, n) {
        var s, a = "object" == typeof n && null !== n, i = a ? n.capture : n;
        (n = a ? function (e) {
            var t = Object.getOwnPropertyDescriptor(e, "passive");
            return t && !0 !== t.writable && void 0 === t.set ? Object.assign({}, e) : e
        }(n) : {}).passive = void 0 !== (s = n.passive) ? s : -1 !== t.indexOf(o) && !0, n.capture = void 0 !== i && i, e.call(this, o, r, n)
    }, EventTarget.prototype.addEventListener._original = e
}


/*
* Accessibility
* Avoid the color/font change blink (Here, not inside the JS)
* Need to read after the Cookies (inject-head is before)
* Converted from jQuery to javascript
* Here also to minify
*/
window.accorAccessibilityStatus = {
    color: 'default',
    font: 'normal',
    letterSpacing: 'normal',
    animation: 'normal',
};

var btn_acc_by = document.getElementById('acc_by');
var btn_acc_bw = document.getElementById('acc_bw');
var btn_acc_spacing = document.getElementById('acc_larger_spacing');
var btn_acc_font = document.getElementById('acc_readable_font');
var btn_acc_anim = document.getElementById('acc_no_animation');
var btn_acc_reset = document.getElementById('acc_reset');
var html = document.getElementsByTagName("html")[0];

var accorAccessibilityCookieName = 'accor_accessibility';
(function () {
    var tmp = Cookies.get(accorAccessibilityCookieName);
    if (tmp) {
        try {
            var obj = JSON.parse(tmp);
            if (typeof obj.color !== 'undefined') {
                accorAccessibilityStatus.color = obj.color;
            }
            if (typeof obj.fontSize !== 'undefined') {
                accorAccessibilityStatus.fontSize = obj.fontSize;
            }
            if (typeof obj.letterSpacing !== 'undefined') {
                accorAccessibilityStatus.letterSpacing = obj.letterSpacing;
            }
            if (typeof obj.font !== 'undefined') {
                accorAccessibilityStatus.font = obj.font;
            }
            if (typeof obj.animation !== 'undefined') {
                accorAccessibilityStatus.animation = obj.animation;
            }
        } catch (e) {
        }
        applyAccessibilityStatus();
    }
})();

function saveAccessibilityStatus() {
    var str = JSON.stringify(accorAccessibilityStatus);
    Cookies.set(accorAccessibilityCookieName, str, 1000);
}

var countTime = 0;

function applyAccessibilityStatus(reset) {
    reset = reset || false;
    if (accorAccessibilityStatus.color === 'bw') {
        html.classList.add("accessibility_BW");
        html.classList.remove("accessibility_BY");
        btn_acc_by.classList.remove('active');
        btn_acc_bw.classList.add('active');
    }
    if (accorAccessibilityStatus.color === 'by') {
        html.classList.add("accessibility_BY");
        html.classList.remove("accessibility_BW");
        btn_acc_bw.classList.remove('active');
        btn_acc_by.classList.add('active');
    }
    if (accorAccessibilityStatus.letterSpacing === 'large') {
        html.classList.add("accessibility_larger_spacing");
        btn_acc_spacing.classList.add('active');
    }
    if (accorAccessibilityStatus.font === 'readable') {
        html.classList.add("accessibility_readable_font");
        btn_acc_font.classList.add('active');
    }
    if (accorAccessibilityStatus.animation === 'animation') {
        html.classList.add("accessibility_no_animation");
        if (countTime < 1) {
            // First time give time to load the Sliders
            setTimeout(function () {
                animationSliders(0);
            }, 3000)
        } else {
            animationSliders(0);
        }
        countTime++;
        btn_acc_anim.classList.add('active');
    }
    if (reset) {
        html.classList.remove("accessibility_BW");
        html.classList.remove("accessibility_BY");
        html.classList.remove("accessibility_readable_font");
        html.classList.remove("accessibility_larger_spacing");
        html.classList.remove("accessibility_no_animation");
        btn_acc_bw.classList.remove('active');
        btn_acc_by.classList.remove('active');
        btn_acc_spacing.classList.remove('active');
        btn_acc_font.classList.remove('active');
        btn_acc_anim.classList.remove('active');
    }
    saveAccessibilityStatus();
}

let btnAccessibilityPanel = document.getElementById("accessibility_btn");
if (btnAccessibilityPanel != null) {
    accessibilityChange();
}


function animationSliders(time) {
    if (window.imageSwiperImagesTwo !== undefined) {
        imageSwiperImagesTwo.params.speed = time;
    }
    if (window.swiperGalleryImages !== undefined) {
        swiperGalleryImages.params.speed = time;
    }
    if (window.swiperBlockImages !== undefined) {
        swiperBlockImages.params.speed = time;
    }
}


function accessibilityChange() {
    btn_acc_bw.addEventListener('click', function () {
        if (!btn_acc_bw.classList.contains('active')) {
            accorAccessibilityStatus.color = 'bw';
            applyAccessibilityStatus();
        } else {
            accorAccessibilityStatus.color = undefined;
            saveAccessibilityStatus()
            btn_acc_bw.classList.remove('active');
            html.classList.remove("accessibility_BW");
        }
    });
    btn_acc_by.addEventListener('click', function () {
        if (!btn_acc_by.classList.contains('active')) {
            accorAccessibilityStatus.color = 'by';
            applyAccessibilityStatus();
        } else {
            accorAccessibilityStatus.color = undefined;
            saveAccessibilityStatus()
            btn_acc_by.classList.remove('active');
            html.classList.remove("accessibility_BY");
        }
    });
    btn_acc_font.addEventListener('click', function () {
        if (!btn_acc_font.classList.contains('active')) {
            accorAccessibilityStatus.font = 'readable';
            applyAccessibilityStatus();
        } else {
            accorAccessibilityStatus.font = undefined;
            saveAccessibilityStatus();
            btn_acc_font.classList.remove('active');
            html.classList.remove("accessibility_readable_font");
        }
    });
    btn_acc_spacing.addEventListener('click', function () {
        if (!document.querySelector('#acc_larger_spacing').classList.contains('active')) {
            accorAccessibilityStatus.letterSpacing = 'large';
            applyAccessibilityStatus();
        } else {
            accorAccessibilityStatus.letterSpacing = undefined;
            saveAccessibilityStatus()
            btn_acc_spacing.classList.remove('active');
            html.classList.remove("accessibility_larger_spacing");
        }
    });
    btn_acc_anim.addEventListener('click', function () {
        if (!btn_acc_anim.classList.contains('active')) {
            accorAccessibilityStatus.animation = 'animation';
            countTime++;
            applyAccessibilityStatus();
        } else {
            accorAccessibilityStatus.animation = undefined;
            saveAccessibilityStatus()
            btn_acc_anim.classList.remove('active');
            html.classList.remove("accessibility_no_animation");
            animationSliders(300);
            countTime++;
        }
    });


    btn_acc_reset.addEventListener('click', function () {
        accorAccessibilityStatus = {
            color: 'default',
            fontSize: 'normal',
            letterSpacing: 'normal',
            animation: 'normal',
        };
        applyAccessibilityStatus(true);
    });
}

// Preload when html have dyslexic class loaded
if (document.getElementsByTagName("html")[0].classList.contains('accessibility_readable_font')) {
    emblems_preload_files("/fonts/OpenDyslexic-Regular.woff2", "font/woff2", "font");
    // emblems_preload_files("/css/fonts_dyslexic.css", "", "style");
    // emblems_preload_files("/fonts/OpenDyslexic-Regular.ttf", "font/ttf", "font");
    // emblems_preload_files("/fonts/OpenDyslexic-Regular.woff", "font/woff", "font");

    function emblems_preload_files(name, type_font, as_type) {
        var preloadLink = document.createElement("link");
        preloadLink.href = theme_url + name;
        preloadLink.rel = "preload";
        preloadLink.as = as_type;
        if (type_font) {
            preloadLink.type = type_font;
        }
        preloadLink.crossOrigin = "anonymous";
        document.head.appendChild(preloadLink);
    }
}

setTimeout(function () {
    var htmlTag = document.getElementsByTagName('html');
    if (htmlTag[0].getAttribute('hc') != null) {
        // Fix background color button on High Contrast Chrome Extension
        // The Chrome is not reading the high-contrast
        var css = '.button-background-gold::after, #backtotop-container-button #scrolltotop.show { background: white; } #backtotop-container-button #scrolltotop::after {border-bottom-color: white;}',
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

        head.appendChild(style);

        style.type = 'text/css';
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

    }
}, 150);
